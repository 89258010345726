const middleware = {}

middleware['auth-from-route'] = require('../middleware/auth-from-route.js')
middleware['auth-from-route'] = middleware['auth-from-route'].default || middleware['auth-from-route']

middleware['com-redirect'] = require('../middleware/com-redirect.js')
middleware['com-redirect'] = middleware['com-redirect'].default || middleware['com-redirect']

middleware['ege-oge-subdomains'] = require('../middleware/ege-oge-subdomains.js')
middleware['ege-oge-subdomains'] = middleware['ege-oge-subdomains'].default || middleware['ege-oge-subdomains']

middleware['online-school-redirect'] = require('../middleware/online-school-redirect.js')
middleware['online-school-redirect'] = middleware['online-school-redirect'].default || middleware['online-school-redirect']

middleware['trailing-slash-redirect'] = require('../middleware/trailing-slash-redirect.js')
middleware['trailing-slash-redirect'] = middleware['trailing-slash-redirect'].default || middleware['trailing-slash-redirect']

export default middleware
